"use client";

import { useEffect } from "react";
import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";
export function Toaster() {
  const {
    toasts
  } = useToast();
  useEffect(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      (CSS as {
        paintWorklet?: {
          addModule?: (url: string) => void;
        };
      })?.paintWorklet?.addModule?.("https://www.unpkg.com/css-houdini-squircle/squircle.min.js");
    } catch (e) {
      console.error("Failed to load squircle worklet", e);
    }
  }, []);
  return <ToastProvider data-sentry-element="ToastProvider" data-sentry-component="Toaster" data-sentry-source-file="toaster.tsx">
      {toasts.map(function ({
      id,
      title,
      description,
      action,
      ...props
    }) {
      return <Toast key={id} {...props}>
            <div className="grid gap-1">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && <ToastDescription>{description}</ToastDescription>}
            </div>
            {action}
            <ToastClose />
          </Toast>;
    })}
      <ToastViewport data-sentry-element="ToastViewport" data-sentry-source-file="toaster.tsx" />
    </ToastProvider>;
}