"use client";

import React from "react";

// Used to create a context wiht values that don't require non null assertions
// annoyingly though, it can't be used to aliviate prop drilling in server components, because it requires "use client"

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const createGenericContext = <T extends unknown>(defaultContext?: T) => {
  // Create a context with a generic parameter or undefined
  const genericContext = React.createContext<T | undefined>(undefined);

  // Check if the value provided to the context is defined or throw an error
  const useGenericContext = () => {
    const contextIsDefined = React.useContext(genericContext);
    const context = contextIsDefined || defaultContext;
    if (!context) {
      throw new Error(
        "useGenericContext must be used within a Provider or have default value provided",
      );
    }
    return context;
  };
  const useGenericOptionalContext = () => {
    const contextIsDefined = React.useContext(genericContext);
    const context = contextIsDefined || defaultContext;
    return context;
  };

  return [useGenericContext, genericContext.Provider, useGenericOptionalContext] as const;
};
