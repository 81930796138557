"use client";

/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useCallback, useEffect } from "react";
import { createGenericContext } from "@/utils/createGenericContext";
const [useIntercomContext, Provider] = createGenericContext<{
  isIntercomHidden: React.MutableRefObject<boolean>;
}>({
  isIntercomHidden: {
    current: false
  }
});
export const IntercomProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const isIntercomHidden = React.useRef<boolean>(false);
  return <Provider value={{
    isIntercomHidden
  }} data-sentry-element="Provider" data-sentry-component="IntercomProvider" data-sentry-source-file="useHideIntercom.tsx">{children}</Provider>;
};
export const useToggleIntercom = () => {
  const {
    isIntercomHidden
  } = useIntercomContext();
  const initialState = isIntercomHidden.current;
  const showIntercom = useCallback(() => {
    if (typeof window === "object" && typeof document === "object" && window.Intercom) {
      try {
        window?.Intercom?.("update", {
          hide_default_launcher: false,
          alignment: "right"
        });
        isIntercomHidden.current = false;
      } catch (e) {
        console.error(e);
      }
    }
  }, [isIntercomHidden]);
  const hideIntercom = useCallback(() => {
    if (typeof window === "object" && typeof document === "object" && window.Intercom) {
      try {
        window?.Intercom?.("update", {
          hide_default_launcher: true,
          alignment: "right"
        });
        isIntercomHidden.current = true;
      } catch (e) {
        console.error(e);
      }
    }
  }, [isIntercomHidden]);
  useEffect(() => {
    return () => {
      if (typeof window === "object" && typeof document === "object" && window.Intercom) {
        try {
          window?.Intercom?.("update", {
            hide_default_launcher: initialState,
            alignment: "right"
          });
          isIntercomHidden.current = initialState;
        } catch (e) {
          console.error(e);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    showIntercom,
    hideIntercom,
    isIntercomHidden
  };
};
export const useShowIntercom = () => {
  const {
    isIntercomHidden
  } = useIntercomContext();
  const initialState = isIntercomHidden.current;
  useEffect(() => {
    window?.Intercom?.("update", {
      hide_default_launcher: false,
      alignment: "right"
    });
    isIntercomHidden.current = false;
    return () => {
      if (typeof window === "object" && typeof document === "object" && window.Intercom) {
        try {
          window?.Intercom?.("update", {
            hide_default_launcher: initialState,
            alignment: "right"
          });
          isIntercomHidden.current = initialState;
        } catch (e) {
          console.error(e);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};