import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/app/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/app/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/leaflet/dist/leaflet.css");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/app/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/modules/effect-cards.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/modules/pagination.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/utils/fonts.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/utils/fonts.tsx\",\"import\":\"Caveat\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin-ext\"],\"display\":\"swap\",\"variable\":\"--font-caveat\"}],\"variableName\":\"caveat\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/global-error.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider"] */ "/app/src/app/QueryClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IntercomProvider"] */ "/app/src/hooks/useHideIntercom.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
