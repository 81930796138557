"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const client = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      // 5 minutes
      refetchOnWindowFocus: false
    }
  }
});
const Provider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  return <QueryClientProvider client={client} data-sentry-element="QueryClientProvider" data-sentry-component="Provider" data-sentry-source-file="QueryClientProvider.tsx">{children}</QueryClientProvider>;
};
export { Provider as QueryClientProvider };